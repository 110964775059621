import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import * as THREE from "three";
import React from "react";

const SkyBox = ({texture}) => {
  const skyboxTexture = useLoader(
    TextureLoader,
    texture
  );

  return (
    <mesh>
      <sphereBufferGeometry args={[200, 200, 200]} />
      <meshBasicMaterial map={skyboxTexture} side={THREE.BackSide} />
    </mesh>
  );
};

export default SkyBox;
