import { OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import TerrainModel from "./TerrainModel";

import TriColor from "../Effects/TriColor";


export default function MotionTerrain({effects}) {
  const props = {
    amplitude: 25,
    frequency: 1.3,
    octaves: 7,
    subDivisions: 256
  };

  return (
    <>
   
    <TerrainModel {...props} />
    {effects && <TriColor />}
    </>
  );
}
