import React, { useState, useRef } from "react"
import { Link } from "wouter"
import "styled-components/macro"
import { a, useSpring } from "@react-spring/web"
import styled, {keyframes} from "styled-components"
import { Canvas } from '@react-three/fiber'
import LightDarkMode from '../LightDarkMode/LightDarkMode'
import Grid from '../Grid/Grid';


const minWidth = 768;

const NavContainer = styled(a.div)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  z-index: 9999;
  @media (min-width: ${minWidth}px) {
    left: 0px;
    top: 0px;
  }

  ${({ open }) => open && `

        height: 100vh;
        background-color: rgba(255,101,40, 0.9);
        backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
    `}

`


const MenuItem = styled(a.span)`
  display: block;
  text-transform: uppercase;
  color: #000000;
  font-size: 2.5rem;
  font-weight: 800;
  text-shadow: 0 13.36px 8.896px rgba(0,0,0, 0.5),0 -2px 1px #333333;
  `

  const fadeInAnimation = (translateX, translateY) => keyframes`
    0% {
        transform: translateX(-50%) translateY(-200%) scaleY(1) scaleX(1);
        transform-origin: 50% 100%;
        filter: blur(40px);
      opacity: 0;
    }
    100% {
        
        transform: translateX(${ translateX }) translateY(${ translateY }) scaleY(1) scaleX(1);
      filter: blur(0);
      transform-origin: 50% 50%;
      opacity: 1;
    }    
    `

const NavLinks = styled(a.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-Index: 9998;
  position: absolute;
  bottom: 0;
  left: 50%; 
  width: 100vw;
  transform: translateX(-50%); 
  animation-name: ${fadeInAnimation("-50%", "0%")};
  animation-duration: 1s;

  @media (min-width: 768px) {
    top: 50%; 
    transform: translate(-50%, -50%);   
    animation-name: ${fadeInAnimation("-50%", "-50%")};
  
  }

`
const Details = styled(a.div)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f0f0f0
  
`

const Logo = styled(a.div)`
  background-image: url("/images/logo/transparent-white.png"); 
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
   width: 60px;
   height: 34px;
   position: absolute;
   z-index: 10000;
   top: 55px;
   left: 50%; 
   transform: translate(-50%, 0%); 
   
  @media (min-width: 768px) {
    left: 70px;   
  }

  ${({ open }) => open && `
  background-image: url("/images/logo/transparent.png"); 
`}

`



function Navigation({ key, mode, toggleMode, size, color }) {
    const [open, setOpen] = useState(false);
    const [clicked, setClicked] = useState(false);
    const textColor = mode ? "#202020":"#f0f0f0"
    const toggleState = () => {
        setClicked(true);
        setOpen(!open);
        toggleMode();
    }

    const ModeButton = {
        width: "150px",
        height: "150px",
        zIndex: 9999,
        position: "absolute",
        top: "0px",
        left: (size.width >= minWidth) ? "0px" : "50%", 
        transform: (size.width >= minWidth) ? "translate(0,0)":"translate(-50%, 0%)"
    }


    let camera = { position: [0, -20, 30], fov: 100 };
    if( size.width >= minWidth)
    {
      camera = { position: [0, -30, 20], fov: 30 };
    }

  const fadeSpring = useSpring({ opacity: open ? 1: 0 });

  return (
    <>
      <NavContainer open={open}>
        <Logo onClick={toggleState} open={open} />
        {!clicked && <div className="circleContainer">
            <div className="circleDot" />
            <div className="circleBar" />
            <div className="circle" onClick={toggleState}>Click Me</div>
          </div>}
        <Canvas concurrent camera={{ position: [0, 0, 20], fov: 100 }} onCreated={({ gl }) => (gl.toneMappingExposure = 1.5)} style={ModeButton}>
            <LightDarkMode mode={mode} toggleMode={toggleState} baseColor={color} />  
        </Canvas>
        {open && 
            <Canvas concurrent camera={camera}  onCreated={({ gl }) => (gl.toneMappingExposure = 1.5)} style={{width: "100vw", height: "100vh", opacity: 0.2}} >        
                <Grid effects={true} />
            </Canvas>
        }
        { open && <NavLinks open={open}>
            <Link to="/" onClick={toggleState}><MenuItem style={{ color: textColor }}>Fox At Play</MenuItem></Link>
            <Link to="/cloudcomputing" onClick={toggleState}><MenuItem style={{ color: textColor }}>Cloud Computing</MenuItem></Link>
            <Link to="/machinelearning" onClick={toggleState}><MenuItem style={{ color: textColor }}>Machine Learning</MenuItem></Link>
            <Link to="/solutionbuilding" onClick={toggleState}><MenuItem style={{ color: textColor }}>Solution Building</MenuItem></Link>
            <Link to="/projects" onClick={toggleState}><MenuItem style={{ color: textColor }}>Projects</MenuItem></Link>
            <Details>
                <span>Fox At Play Sdn Bhd</span>
                <span>Menara KEN TTDI</span>
                <span>Unit D2, Level 11, 37 Jalan Burhanuddin Helmi</span>
                <span>Taman Tun Dr Ismail, Kuala Lumpur, 60000</span>
                <span>Malaysia</span><br/>
                <span>Say <a style={{color: textColor}} href="mailto:hello@foxatplay.com">hello@foxatplay.com</a></span>
            </Details>
          
          </NavLinks>
        }
        {/*<ul className="grid">
					<li>
          <span className="ot-letter-left"><span data-letter="F">F</span></span>
					<span className="ot-letter-top"><span data-letter="o">o</span></span>
					<span className="ot-letter-right"><span data-letter="x">x</span></span>
          </li>
          <li>
					<span className="ot-letter-bottom"><span data-letter="A">A</span></span>
					<span className="ot-letter-left"><span data-letter="t">t</span></span>
					</li>
          <li>
          <span className="ot-letter-left"><span data-letter="P">P</span></span>
					<span className="ot-letter-right"><span data-letter="l">l</span></span>
					<span className="ot-letter-left"><span data-letter="a">a</span></span>
					<span className="ot-letter-top"><span data-letter="y">y</span></span>
					</li>
      </ul>*/}
      </NavContainer>
    </>
  )
}

export { Navigation }
