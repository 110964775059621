import SkyBox from "../SkyBox/SkyBox";
import CloudScene from "../CloudScene/CloudScene";
import SparkCloud from "../SparkCloud/SparkCloud";
import GlassModel from "../GlassModel/GlassModel";
import MotionTerrain from "../MotionTerrain/MotionTerrain";
import Grass from "../Grass/Grass";
import Bubbles from "../Bubbles/Bubbles";
import DisplacementTerrain from "../DisplacementTerrain/DisplacementTerrain";
import * as THREE from "three";
import React, { Suspense, useRef } from "react";
import { useThree } from "@react-three/fiber";
import { Flex, Box } from "@react-three/flex";
import { PerspectiveCamera, OrbitControls, Sphere } from "@react-three/drei";
import { a } from "@react-spring/three";
import { Switch, Route } from "wouter";

const ContentContainerMedia = ({ transition }) => {
  const group = useRef();
  const { size } = useThree();

  return transition(({ opacity, ...props }, location) => (
    <a.group ref={group} {...props} position={[0, 0, 0]}>
      <Flex size={[size.width, size.height, 0]}>
        <Switch location={location}>
          <Route path="/">
            <Box>
              <PerspectiveCamera
                position={[0.0, 50, 5]}
                fov={50}
                near={0.01}
                far={1000}
                makeDefault
              />
              <OrbitControls
                screenSpacePanning={false}
                autoRotate={true}
                autoRotateSpeed={0.5}
                enablePan={false}
                maxPolarAngle={1}
                minPolarAngle={1}
                enableZoom={false}
              />
              <Suspense fallback={null}>
                <MotionTerrain effects={true} />
                <SkyBox texture="/images/skybox/background-1.jpg" />
              </Suspense>
            </Box>
          </Route>
          <Route path="/cloudcomputing">
            <Box>
              <PerspectiveCamera
                zoom={0.5}
                fov={50}
                near={0.01}
                far={1000}
                makeDefault
              />

              <OrbitControls
                screenSpacePanning={false}
                autoRotate={true}
                autoRotateSpeed={1}
                enablePan={false}
                maxPolarAngle={1}
                minPolarAngle={1}
                enableZoom={false}
              />

              <Suspense fallback={null}>
                <CloudScene />
                <Grass
                  effects={false}
                  intensity={0.0}
                  yAdjustment={20}
                  width={200}
                />
                <SkyBox texture="/images/skybox/background-3.jpg" />
              </Suspense>
            </Box>
          </Route>
          <Route path="/machinelearning">
            <Box>
              <PerspectiveCamera
                makeDefault
                position={[0, 0.5, 3]}
                fov={50}
                near={0.01}
                far={1000}
              ></PerspectiveCamera>
              <OrbitControls
                screenSpacePanning={false}
                autoRotate={true}
                autoRotateSpeed={1}
                enablePan={false}
                maxPolarAngle={1}
                minPolarAngle={1}
                enableZoom={false}
              />

              <Suspense fallback={null}>
                <GlassModel />
                <SparkCloud
                  particles={true}
                  effects={true}
                  sparks={false}
                  intensity={0.01}
                />
                <SkyBox texture="/images/skybox/background-5.jpg" />
              </Suspense>
            </Box>
          </Route>

          <Route path="/projects">
            <Box>
              <Suspense fallback={null}>
                <PerspectiveCamera
                  makeDefault
                  position={[0, 0, 50]}
                  fov={50}
                  near={0.01}
                  far={1000}
                ></PerspectiveCamera>
                <OrbitControls
                screenSpacePanning={false}
                autoRotate={true}
                enablePan={false}
                enableZoom={false}
              />
                <Bubbles effects={true} />
                <SkyBox texture="/images/skybox/background-2.jpg" />
              </Suspense>
            </Box>
          </Route>
          <Route path="/solutionbuilding">
            <Box>
              <PerspectiveCamera
                position={[0.0, 0.8, 0.0]}
                fov={50}
                near={0.01}
                far={1000}
                makeDefault
              ></PerspectiveCamera>
              <OrbitControls
                screenSpacePanning={false}
                autoRotate={true}
                enablePan={false}
                maxPolarAngle={1}
                minPolarAngle={1}
                enableZoom={false}
              />
              <Suspense fallback={null}>
                <DisplacementTerrain effects={true} />
                <SkyBox texture="/images/skybox/background-4.jpg" />
              </Suspense>
            </Box>
          </Route>
        </Switch>
      </Flex>
    </a.group>
  ));
};
export default ContentContainerMedia;
