import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ContentBox } from "./Styles";
import { Switch, Route } from "wouter";

const ContentContainerMobile = ({ transition, size, mode }) => {
  let isMobile = true;
  let width = 250;
  let height = 250;
  if (size.width >= 768) {
    isMobile = false;
  }
  return transition(({ opacity, transform }, location) => {
    return (
      <Switch location={location}>
        <Route path="/">
          <ContentBox
            mode={mode}
            style={{ transform, opacity: mode ? opacity : 0, width, height }}
          >
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              showArrows={false}
              width={300}
            >
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div>We can help</div>
                <div>your business navigate</div>
                <div>an everchanging</div>
                <div>digital landscape.</div>
                <div>We are</div>
                <div style={{ marginBottom: "20px" }}>
                  <span style={{ fontFamily: "Pacifico" }}>Fox At Play</span>
                </div>
              </div>
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div>Our people have</div>
                <div>knowledge and experience</div>
                <div>consulting to industry sectors</div>
                <div>including government, finance,</div>
                <div>sales &amp; marketing, entertainment,</div>
                <div style={{ marginBottom: "20px" }}>
                  education and training
                </div>
              </div>
            </Carousel>
          </ContentBox>
        </Route>
        <Route path="/cloudcomputing">
          <ContentBox
            style={{ transform, opacity: mode ? opacity : 0, width, height }}
          >
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              showArrows={false}
              width={300}
            >
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div>We specialise in cloud</div>
                <div>deployed solutions.</div>
                <div></div>
                <div>Our goal is to provide scalable,</div>
                <div>robust, extensible and highly</div>
                <div style={{ marginBottom: "20px" }}>available software.</div>
              </div>
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div style={{ marginTop: "20px" }}>We can build from the</div>
                <div>ground-up or convert</div>
                <div>a legacy application into a</div>
                <div >
                  leading-edge solution.
                </div>
              </div>
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div>Legacy systems are typically</div>
                <div>not designed to scale to demand.</div>
                <div></div>
                <div>Our cloud solutions scale not only</div>
                <div>providing better performance but also</div>
                <div style={{ marginBottom: "20px" }}>more economically.</div>
              </div>
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div>Our solutions are designed to</div>
                <div>be accessible from anywhere,</div>
                <div>at any time from any device.</div>
                <div></div>
                <div>Stay connected with to your business</div>
                <div style={{ marginBottom: "20px" }}>
                  with purpose build mobile apps.
                </div>
              </div>
            </Carousel>
          </ContentBox>
        </Route>
        <Route path="/machinelearning">
          <ContentBox
            style={{ transform, opacity: mode ? opacity : 0, width, height }}
          >
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              showArrows={false}
              width={300}
            >
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div style={{ marginTop: "20px" }}>
                  We can provide insight and
                </div>
                <div>productivity gains using </div>
                <div>curated machine learning and</div>
                <div>artifical intelligence.</div>
              </div>
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div>Digitisation and automation</div>
                <div>can eradicate manual and error prone</div>
                <div>processes and replace them with </div>
                <div>workflows that increases efficiency</div>
                <div>and quality of outcome</div>
              </div>

              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div>Analyse and identify trends,</div>
                <div>gain valuable understanding of</div>
                <div>your business' performance and customers.</div>
                <div></div>
                <div>Improve customer satisfaction and add</div>
                <div style={{ marginBottom: "20px" }}>
                  value to interactions.
                </div>
              </div>
            </Carousel>
          </ContentBox>
        </Route>

        <Route path="/projects">
          <ContentBox
            style={{ transform, opacity: mode ? opacity : 0, width, height }}
          >
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              showArrows={false}
              width={350}
              height={350}
              className="projects"
            >
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage:
                      "url('/images/projects/machine-learning.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    fontSize: "2rem",
                    color: "#ffffff",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                  }}
                >
                  Machine Learning
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage:
                      "url('/images/projects/face-detection.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    fontSize: "2rem",
                    color: "#ffffff",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                  }}
                >
                  Face Detection &amp;
                  <br />
                  Mapping
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage:
                      "url('/images/projects/image-classification.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    color: "#ffffff",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                  }}
                >
                  Image Classification
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage:
                      "url('/images/projects/acoustic-analysis.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    fontSize: "2rem",
                    color: "#ffffff",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                  }}
                >
                  Acoustic Analysis
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/segmentation.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    fontSize: "2rem",
                    color: "#ffffff",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                  }}
                >
                  Customer
                  <br />
                  Segmentation
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage:
                      "url('/images/projects/recommendations.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    fontSize: "2rem",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                  }}
                >
                  Recommendations
                  <br />
                  Engine
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/appdev.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    color: "#ffffff",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                  }}
                >
                  Application
                  <br />
                  Development
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/crm.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    color: "#ffffff",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                  }}
                >
                  CRM
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/e-commerce.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                  }}
                >
                  E-Commerce
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/job-portal.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                  }}
                >
                  Job Portal
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/bookings.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    color: "#ffffff",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                  }}
                >
                  Travel &amp;
                  <br />
                  Bookings
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/slide03.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    color: "#ffffff",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                  }}
                >
                  Mobile Apps
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/case01.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                  }}
                >
                  Gamification
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/whatsapp-use.png')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                  }}
                >
                  Productivity
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/case03.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                  }}
                >
                  Visualisation &amp;
                  <br />
                  Analytics
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/case05.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                  }}
                >
                  Tracking &amp;
                  <br />
                  Zone Management
                </div>
              </div>
              <div className="fade-in" style={{ fontSize: "2rem" }}>
                <div
                  style={{
                    margin: "20px 0px 20px 45px",
                    borderRadius: "50%",
                    backgroundImage: "url('/images/projects/case08.jpg')",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "260px",
                    height: "260px",
                  }}
                />
                <div
                  style={{
                    
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                  }}
                >
                  Messaging
                </div>
              </div>
            </Carousel>
          </ContentBox>
        </Route>
        <Route path="/solutionbuilding">
          <ContentBox
            style={{ transform, opacity: mode ? opacity : 0, width, height }}
          >
            <Carousel
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              showArrows={false}
              width={300}
            >
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div style={{ marginTop: "20px" }}>
                  We don't just build software.
                </div>
                <div>We build solutions.</div>
                <div></div>
                <div>A holistic approach that addresses</div>
                <div>your business needs and</div>
                <div>solves problems.</div>
                <div style={{ marginBottom: "20px" }}></div>
              </div>
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div>We have expertise in </div>
                <div>end-to-end solution building.</div>
                <div></div>
                <div>How to architect it.</div>
                <div>How to construct it.</div>
                <div>and the infrastructure needed</div>
                <div style={{ marginBottom: "20px" }}>to support it.</div>
              </div>
              <div
                className="fade-in"
                style={{ fontSize: "1.25rem" }}
              >
                <div style={{ marginTop: "20px" }}>
                  Our critical thinking and
                </div>
                <div>business analysis provides</div>
                <div>product and rollout strategy</div>
                <div>to aid expansion and relieve</div>
                <div>pain points.</div>
              </div>
            </Carousel>
          </ContentBox>
        </Route>
      </Switch>
    );
  });
};
export default ContentContainerMobile;
