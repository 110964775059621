import { useState, useRef } from "react";
import "./NoiseMaterial";
import { useFrame } from "@react-three/fiber";
import { Stars } from "@react-three/drei";

const WIDTH = 300;
const HEIGHT = 300;

export default function Terrain({
  amplitude,
  subDivisions,
  frequency,
  octaves
}) {
  const ref = useRef(null);
  const divisions = Math.floor(subDivisions);
  const [degrees, setDegrees] = useState(0);
  
  useFrame(() => {
    if (ref.current) {
      ref.current.uniforms.time.value += 0.003;
    }
  });

  return (
    <mesh rotation-x={-0.5 * Math.PI} position-y={-50}>
       <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade />
      <planeBufferGeometry args={[WIDTH, HEIGHT, divisions, divisions]} />
      <noiseMaterial
        ref={ref}
        amplitude={amplitude}
        frequency={frequency}
        octaves={octaves}
      />

    </mesh>
  );
}
