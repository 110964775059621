
import { Navigation } from "./components/Navigation/Navigation";
import SwipeMe from "./components/SwipeMe/SwipeMe";
import { useWindowSize } from "./utils/useWindowSize";
import React, {
  Suspense,
  useState,
} from "react";
import {
  Canvas,
  extend,
} from "@react-three/fiber";
import {
  Loader,
  OrbitControls,
} from "@react-three/drei";
import ContentContainer from './components/ContentContainer/ContentContainer'
import ContentContainerMobile from './components/ContentContainer/ContentContainerMobile'
import ContentContainerMedia from './components/ContentContainer/ContentContainerMedia'
import "./App.css";

import { useTransition, useSpring } from "@react-spring/core";
import { useLocation, Switch, Route } from "wouter";

extend({ OrbitControls });


export default function App() {
  const [mode, setMode] = useState(true);
  const size = useWindowSize();
  // Current route
  const [location] = useLocation();

  let active = mode;

  let color = "#001750";
  let width = "300px";
  let height = "300px";

  switch (location) {
    case "/solutionbuilding":
      color = "#5a0035";
      break;
    case "/projects":
      color = "#333344";
      break;
    case "/machinelearning":
      color = "#870e00";
      break;
    case "/cloudcomputing":
      color = "#022204";
      active = false;
      break;
  }

  const transition = useTransition(location, {
    from: {
      position: [0, 0, -20],
      rotation: [0, Math.PI, 0],
      scale: [0, 0, 0],
      opacity: 0,
    },
    enter: {
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      scale: [1, 1, 1],
      opacity: 1,
    },
    leave: {
      position: [0, 0, 20],
      rotation: [0, -Math.PI, 0],
      scale: [0, 0, 0],
      opacity: 0,
    },
    config: { duration: 1000 },
  });

  const contentTransition = useTransition(location, {
    from: { opacity: 0, transform: "translate3d(-50%, -1000%, 0px)" },
    enter: { opacity: 1, transform: "translate3d(-50%, 0px, 0px)" },
    leave: { opacity: 0, transform: "translate3d(-100%, 0px, 0px)" },
    config: { mass: 1, tension: 170, friction: 26 },
  });
  //config: () => (n) => n === "opacity" && { friction: 60 },
  const toggleMode = () => {
    setMode(!mode);
  };
  return (
    <>
      {size.width >= 768?<ContentContainer transition={contentTransition} size={size} mode={mode} />:<ContentContainerMobile transition={contentTransition} size={size} mode={mode} />}

      <Canvas
        concurrent
        camera={{ position: [0, 0, 20], fov: 100 }}
        onCreated={(state) => {
          state.toneMappingExposure = 1.5;
        }}
      >
        <Suspense fallback={null}>
          <ContentContainerMedia transition={transition} />
        </Suspense>
      </Canvas>
      <Navigation
        mode={mode}
        toggleMode={toggleMode}
        size={size}
        color={color}
      />
      <SwipeMe active={active} />
      <Loader />
    </>
  );
}
