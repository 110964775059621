import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ContentBox, Container } from "./Styles";
import { Switch, Route } from "wouter";

const Slide = (props) => {
  const fontSize = props.fontSize;

  return (
    <ContentBox
      mode={props.mode}
      style={{
        opacity: props.mode ? props.opacity : 0,
        transform: props.transform,
        width: props.width,
        height: props.height,
        right: props.right,
        top: props.top,
        left: props.left,
        bottom: props.bottom,
        fontSize: props.fontSize,
      }}
    >
      <div className="fade-in">{props.children}</div>
    </ContentBox>
  );
};
const ContentContainer = ({ transition, size, mode }) => {
  let isMobile = true;
  let width = 300;
  let height = 300;
  if (size.width >= 768) {
    isMobile = false;
  }
  return transition(({ opacity, transform }, location) => {
    return (
      <Switch location={location}>
        <Route path="/">
          <Container style={{ right: "400px", bottom: "200px" }}>
            <Slide
              right="-700px"
              bottom="-200px"
              fontSize="1.5rem"
              opacity={opacity}
              transform={transform}
              width={width}
              height={height}
              mode={mode}
            >
              <div className="fade-in">
                <div>Our people have</div>
                <div>knowledge and experience</div>
                <div>consulting to industry sectors</div>
                <div>including government, finance,</div>
                <div>sales &amp; marketing, entertainment,</div>
                <div style={{ marginBottom: "20px" }}>
                  education and training
                </div>
              </div>
            </Slide>
            <Slide
              width={350}
              height={350}
              left="-50px"
              fontSize="1.75rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div>We can help</div>
              <div>your business navigate</div>
              <div>an everchanging</div>
              <div>digital landscape.</div>
              <div>We are</div>
              <div style={{ marginTop: "20px" }}>
                <span style={{ fontFamily: "Pacifico", fontSize: "2.25rem" }}>
                  Fox At Play
                </span>
              </div>
            </Slide>
          </Container>
        </Route>
        <Route path="/cloudcomputing">
          <Container style={{ right: "400px", bottom: "200px" }}>
            <Slide
              width={width}
              height={height}
              left="200px"
              bottom="170px"
              fontSize="1.5rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div>Our solutions are designed to</div>
              <div>be accessible from anywhere,</div>
              <div>at any time from any device.</div>
              <div></div>
              <div>Stay connected with to your business</div>
              <div>with purpose build mobile apps.</div>
            </Slide>

            <Slide
              width={width}
              height={height}
              left="-50px"
              bottom="-50px"
              fontSize="1.5rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div>Legacy systems are typically</div>
              <div>not designed to scale to demand.</div>
              <div></div>
              <div>Our cloud solutions scale not only</div>
              <div>providing better performance but also</div>
              <div>more economically.</div>
            </Slide>

            <Slide
              width={250}
              height={250}
              left="-350px"
              bottom="-200px"
              fontSize="1.5rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div>We can build from the</div>
              <div>ground-up or convert</div>
              <div>a legacy application into a</div>
              <div>leading-edge solution.</div>
            </Slide>
            <Slide
              width={width}
              height={height}
              left="-600px"
              bottom="-100px"
              fontSize="1.5rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div>We specialise in cloud</div>
              <div>deployed solutions.</div>
              <div></div>
              <div>Our goal is to provide scalable,</div>
              <div>robust, extensible and highly</div>
              <div>available software.</div>
            </Slide>
          </Container>
        </Route>
        <Route path="/machinelearning">
          <Container style={{ right: "400px", bottom: "200px" }}>
            <Slide
              width={250}
              height={250}
              left="200px"
              bottom="100px"
              fontSize="1.25rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div style={{ marginTop: "20px" }}>
                Analyse and identify trends,
              </div>
              <div>gain valuable understanding of</div>
              <div>your business' performance and customers.</div>
              <div></div>
              <div>Improve customer satisfaction and add</div>
              <div>value to interactions.</div>
            </Slide>
            <Slide
              width={width}
              height={height}
              left="-50px"
              bottom="-100px"
              fontSize="1.5rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div>Digitisation and automation</div>
              <div>can eradicate manual and error prone</div>
              <div>processes and replace them with </div>
              <div>workflows that increases efficiency</div>
              <div>and quality of outcome</div>
            </Slide>
            <Slide
              width={250}
              height={250}
              left="-150px"
              bottom="200px"
              fontSize="1.5rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div style={{ marginTop: "20px" }}>
                We can provide insight and
              </div>
              <div>productivity gains using </div>
              <div>curated machine learning and</div>
              <div>artifical intelligence.</div>
            </Slide>
          </Container>
        </Route>

        <Route path="/projects">
          <Container style={{ right: "400px", bottom: "200px" }}>
            <ContentBox
              style={{
                transform,
                opacity: mode ? opacity : 0,
                width: "200px",
                height: "200px",
                left: "150px",
                bottom: "100px",
              }}
            >
              <div className="fade-in" style={{ fontSize: "1.25rem" }}>
                <div>We update this page with</div>
                <div>new information.</div>
                <div>Come back and check it out!</div>
              </div>
            </ContentBox>
            <ContentBox
              style={{
                transform,
                opacity: mode ? opacity : 0,
                width: "400px",
                height: "400px",
                left: "-100px",
                bottom: "-200px",
              }}
            >
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                showArrows={false}
                width={350}
                height={350}
              >
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage:
                        "url('/images/projects/machine-learning.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      color: "#ffffff",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                    }}
                  >
                    Machine Learning
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage:
                        "url('/images/projects/face-detection.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      color: "#ffffff",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                    }}
                  >
                    Face Detection &amp;
                    <br />
                    Mapping
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage:
                        "url('/images/projects/image-classification.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      color: "#ffffff",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                    }}
                  >
                    Image Classification
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage:
                        "url('/images/projects/acoustic-analysis.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      color: "#ffffff",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                    }}
                  >
                    Acoustic Analysis
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage:
                        "url('/images/projects/segmentation.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      color: "#ffffff",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                    }}
                  >
                    Customer
                    <br />
                    Segmentation
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage:
                        "url('/images/projects/recommendations.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                    }}
                  >
                    Recommendations
                    <br />
                    Engine
                  </div>
                </div>
              </Carousel>
            </ContentBox>
            <ContentBox
              style={{
                transform,
                opacity: mode ? opacity : 0,
                width: "400px",
                height: "400px",
                left: "-500px",
                bottom: "-50px",
              }}
            >
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                showArrows={false}
                width={350}
                height={350}
              >
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/appdev.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      color: "#ffffff",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                    }}
                  >
                    Application
                    <br />
                    Development
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/crm.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      color: "#ffffff",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                    }}
                  >
                    CRM
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/e-commerce.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                    }}
                  >
                    E-Commerce
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/job-portal.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                    }}
                  >
                    Job Portal
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/bookings.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      color: "#ffffff",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                    }}
                  >
                    Travel &amp;
                    <br />
                    Bookings
                  </div>
                </div>
              </Carousel>
            </ContentBox>
            <ContentBox
              style={{
                transform,
                opacity: mode ? opacity : 0,
                width: "400px",
                height: "400px",
                left: "-800px",
                bottom: "200px",
              }}
            >
              <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                showArrows={false}
                width={350}
                height={350}
              >
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/slide03.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      color: "#ffffff",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(255,255,255,0.75)",
                    }}
                  >
                    Mobile Apps
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/case01.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                    }}
                  >
                    Gamification
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage:
                        "url('/images/projects/whatsapp-use.png')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                    }}
                  >
                    Productivity
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/case03.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                    }}
                  >
                    Visualisation &amp;
                    <br />
                    Analytics
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/case05.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                    }}
                  >
                    Tracking &amp;
                    <br />
                    Zone Management
                  </div>
                </div>
                <div className="fade-in" style={{ fontSize: "1.5rem" }}>
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                      borderRadius: "50%",
                      backgroundImage: "url('/images/projects/case08.jpg')",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      width: "350px",
                      height: "350px",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "3rem",
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%,-50%)",
                      textShadow: "0px 0px 10px rgba(0,0,0,0.75)",
                    }}
                  >
                    Messaging
                  </div>
                </div>
              </Carousel>
            </ContentBox>
          </Container>
        </Route>
        <Route path="/solutionbuilding">
          <Container style={{ right: "400px", bottom: "200px" }}>
            <Slide
              width={200}
              height={200}
              left="125px"
              bottom="-200px"
              fontSize="1.25rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div style={{ marginTop: "20px" }}>Our critical thinking and</div>
              <div>business analysis provides</div>
              <div>product and rollout strategy</div>
              <div>to aid expansion and relieve</div>
              <div>pain points.</div>
            </Slide>
            <Slide
              width={250}
              height={250}
              left="-100px"
              bottom="-100px"
              fontSize="1.25rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div style={{ marginTop: "20px" }}>We have expertise in </div>
              <div>end-to-end solution building.</div>
              <div></div>
              <div>How to architect it.</div>
              <div>How to construct it.</div>
              <div>and the infrastructure needed</div>
              <div>to support it.</div>
            </Slide>
            <Slide
              width={width}
              height={height}
              left="-400px"
              bottom="-200px"
              fontSize="1.5rem"
              opacity={opacity}
              transform={transform}
              mode={mode}
            >
              <div style={{ marginTop: "40px" }}>
                We don't just build software.
              </div>
              <div>We build solutions.</div>
              <div></div>
              <div>A holistic approach that addresses</div>
              <div>your business needs and</div>
              <div>solves problems.</div>
              <div style={{ marginBottom: "20px" }}></div>
            </Slide>
          </Container>
        </Route>
      </Switch>
    );
  });
};
export default ContentContainer;
