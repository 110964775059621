import React, { useState, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useGLTF, Environment, Loader, OrbitControls, PerspectiveCamera } from '@react-three/drei'
import useTransmissionMaterial from './use-material'
import usePostprocessing from './use-postprocessing'


function GlassObj({ nodes }) {
  const [degrees, setDegrees] = useState(0);
  const [ref, pipeline] = useTransmissionMaterial({
    frontMaterial: { color: 'white' },
    backMaterial: { color: 'white' },
    transmissionMaterial: {
      transmission: 1,
      transmissionIntensity: 2.5,
      distortionIntensity: 0.75,
      fresnel: 3,
      fresnelAmplifier: 2
    }
  })
  usePostprocessing(pipeline)
  
  
  //return <mesh ref={ref} geometry={nodes.Strand_Cube001.geometry} />
  //return <mesh ref={ref} geometry={nodes.Free_Brain001.geometry} />
  return  <mesh rotation-z={1.57} rotation-x={1.57} position={[0,-0.5,0]} ref={ref} geometry={nodes.Brain_Model.geometry} />

}

export default function GlassModel({controls}) {
  const { nodes } = useGLTF('/images/glassmodel/brain.glb')

  return (
    <>
        <Suspense fallback={null}>
          <GlassObj nodes={nodes}  />
        </Suspense>
        <directionalLight position={[20, 20, 5]} />
        {controls && <OrbitControls /> }
        
    </>
  )
}
