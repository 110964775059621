import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { DoubleSide, RepeatWrapping, sRGBEncoding } from "three";
import {
  useTexture
} from "@react-three/drei";

import { vertexShader, fragmentShader } from "./shaders";
import TriColor from "../Effects/TriColor";


export default function DisplacementTerrain() {
  return (
    <>
        <Suspense fallback={null}>
          <group>
            
            <Displacement />
          </group>
          <ambientLight />
        </Suspense>

      </>
  );
}

function Displacement({effects}) {
  // Load the heightmap image
  const heightMap = useTexture("/images/terrain/heightmap.png");
  // Apply some properties to ensure it renders correctly
  heightMap.encoding = sRGBEncoding;
  heightMap.wrapS = RepeatWrapping;
  heightMap.wrapT = RepeatWrapping;
  heightMap.anisotropy = 16;

  return (
    <mesh
      position={[0, -0.3, 0]}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={[1 / 1024, 1 / 1024, 1 / 1024]}
    >

      <planeBufferGeometry args={[2048, 2048, 256, 256]} />
      <shaderMaterial
        uniforms={{
          // Feed the heightmap
          bumpTexture: { value: heightMap },
          // Feed the scaling constant for the heightmap
          bumpScale: { value: 300 }
        }}
        // Feed the shaders as strings
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        side={DoubleSide}
      />
      <TriColor />
    </mesh>
  );
}
