import * as THREE from "three"
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from "@react-three/postprocessing"


const DarkVignette = () =>{
  return <>
      <EffectComposer>
        <Vignette eskil={false} offset={0.1} darkness={1.1} />
      </EffectComposer>
    </>
}
export default DarkVignette
