import React, { useState } from "react"
import "styled-components/macro"
import { a } from "@react-spring/web"
import styled, {keyframes} from "styled-components"

const minWidth = 768;
const Container = styled(a.div)`
  position: absolute;
  z-index: 9998;
`
const ContentBox = styled(a.div)`
  position: absolute;
  z-index: 9998;
  transform: translateX(-50%); 
  will-change: opacity;
  font-size: 1.5rem;
  padding: 20px;
  text-shadow: 0 2px 4px rgba(0,0,0,0.3);
  background: rgba( 255, 255, 255, 0.25 );
	box-shadow: 0 8px 32px 0 rgba( 38, 38, 38, 0.37 );
	backdrop-filter: blur( 10.0px );
	-webkit-backdrop-filter: blur( 10.0px );
	border-radius: 50%;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
  margin: auto;
  font-family: "Yanone Kaffeesatz";
  text-align: center;
  box-sizing: content-box;
  white-space: nowrap;
  left: 50%;
  bottom: 0px;
  margin-bottom: 20px;


  &>div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    vertical-align: middle;
  }  

  &>div div {
    padding-top: 5px;
    padding-bottom: 5px;  
  }  

  @media (min-width: ${minWidth}px) {
    font-size: 2rem;
  }
  
`

const Line = styled(a.div)`
  position: relative;
  width: 100%;
  will-change: transform;
  overflow: hidden;
  line-height: 1.2em;
`




export { Container, ContentBox, Line }
